<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-md-4 pl-0">
          <h3>{{ syncLog.code }}</h3>
          <sync-badge
            class="mr-3 p-2 rounded-status-tag"
            :status="syncLog?.status"
            :text="$t('SYNC_LOGS.SYNC_STATUS_' + syncLog?.status)"
          />
        </div>

        <div class="col-md-8 pr-0 text-right">
          <base-button
            :loading="loading"
            class="elite-button btn-outline-success btn-success"
            icon
            size="sm"
            v-if="
              syncLog.status == SYNC_STATUS_FAILED &&
              $currentUserCan($permissions.PERM_EDIT_SYNC_LOGS)
            "
            @click="relunchSync"
          >
            <span class="btn-inner--icon">
              <i class="fas fa-sync"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("SYNC_LOGS.RELAUNCH") }}
            </span>
          </base-button>

          <base-button
            :loading="loading"
            class="elite-button"
            :class="{
              'btn-outline-warning btn-warning': !syncLog.disable_attempts,
              'btn-outline-success btn-success': syncLog.disable_attempts,
            }"
            icon
            size="sm"
            v-if="
              syncLog.status == SYNC_STATUS_FAILED &&
              $currentUserCan($permissions.PERM_EDIT_SYNC_LOGS)
            "
            @click="toggleAttempts"
          >
            <span class="btn-inner--icon">
              <i class="fas fa-info-circle"></i>
            </span>
            <span class="btn-inner--text">
              {{
                syncLog.disable_attempts
                  ? $t("SYNC_LOGS.ENABLE_AUTO_ATTEMPTS")
                  : $t("SYNC_LOGS.DISABLE_AUTO_ATTEMPTS")
              }}
            </span>
          </base-button>

          <base-button
            class="elite-button btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_SYNC_LOGS)"
            @click="deleteSyncLog"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="syncLog.entity_type">
          <dt>{{ $t("SYNC_LOGS.ENTITY_TYPE") }}</dt>
          <dd>
            {{ $t("SYNC_LOGS.ENTITY_TYPE_" + syncLog?.entity_type) }}
          </dd>
        </dl>

        <dl class="row" v-if="syncLog.action">
          <dt>{{ $t("SYNC_LOGS.ACTION") }}</dt>
          <dd class="text-capitalize">
            <sync-badge
              class="mr-3 p-2 rounded-status-tag"
              :status="syncLog?.action"
              :text="$t('SYNC_LOGS.ACTION_' + syncLog?.action)"
            />
          </dd>
        </dl>

        <dl class="row" v-if="syncLog.request_from">
          <dt>{{ $t("SYNC_LOGS.REQUEST_FROM") }}</dt>
          <dd class="text-uppercase">
            {{ syncLog.request_from ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SYNC_LOGS.REQUEST_DATA") }}</dt>
          <dd>
            <vue-json-pretty :data="syncLog.request_data" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SYNC_LOGS.RESPONSE_DATA") }}</dt>
          <dd>
            <vue-json-pretty :data="syncLog.response_data" />
          </dd>
        </dl>

        <dl class="row" v-if="syncLog.attempts">
          <dt>{{ $t("SYNC_LOGS.ATTEMPS") }}</dt>
          <dd class="text-uppercase">
            {{ syncLog.attempts }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SYNC_LOGS.LAST_ATTEMPT_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(syncLog.last_attempt_at) }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="syncLog.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SYNC_LOGS.AUTO_ATTEMPTS") }}</dt>
          <dd>
            {{ syncLog.disable_attempts ? $t("COMMON.NO") : $t("COMMON.YES") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(syncLog.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(syncLog.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import SyncBadge from "../components/SyncBadge.vue";

import {
  SYNC_STATUS_PENDING,
  SYNC_STATUS_SUCCESS,
  SYNC_STATUS_FAILED,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
} from "@/constants/syncLogs";

export default {
  name: "sync-log-view-global",

  components: { VueJsonPretty, SyncBadge },

  props: {
    syncLog: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SYNC_STATUS_FAILED: SYNC_STATUS_FAILED,
    };
  },

  computed: {},

  created() {},

  methods: {
    syncLogUpdated() {
      this.$emit("syncLogUpdated", true);
    },
    relunchSync() {
      this.$emit("relunchSync", this.syncLog);
    },
    toggleAttempts() {
      this.$emit("toggleAttempts", this.syncLog);
    },
    deleteSyncLog() {
      this.$emit("deleteSyncLog", this.signature);
    },
  },

  mounted() {},

  watch: {
    syncLog(syncLog) {},
  },
};
</script>
